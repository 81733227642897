// import {initBridge} from "@/utils/jsBridge"
// initBridge();
// //跳转到APP端
// export default function linkAPP(data){
//     window.setupWebViewJavascriptBridge((bridge) => {
//         alert(1)
//         bridge.callHandler("clientFunction", data, (res) => {
//             alert(2)
//             console.log(res)
//         })
//     })
// }
import vue from "vue";
import CallApp from "callapp-lib";

import { appOptions } from "@/config/settingConfig/web.js";

const options = {
  scheme: {
    protocol: "qiahaoart", //APP 协议，URL Scheme 的 scheme 字段，就是你要打开的 APP 的标识。
    host: "com.qiahao.art",
  },
  intent: {
    package: "com.qiahao.art",
    scheme: "qiahaoart",
  }, //安卓原生谷歌浏览器必须传递 Intent 协议地址，才能唤起 APP。
  appstore: appOptions.appStoreUrl, //填写appstore的下载地址
  yingyongbao: "https://a.app.qq.com/error_pages", //填写应用宝的下载地址
  timeout: 5000,
  fallback: "https://outexp-beta.cdn.qq.com/outbeta/2022/01/22/comqiahaoart_1.0.1_9567140d-b706-54b6-a76e-58f746bac12c.apk", 
  //填写唤端失败后跳转的地址。
};
const callLib = new CallApp(options);
// function checkUrl(url) {
//   if (url.indexOf('#') !== -1) {
//     return url.replace(/#/g, 'hashrouter');
//   }
//   return url;
// }
export default function linkAPP(data) {
  // let path = window.location.href;
  // url = checkUrl(path);
  // console.log(data);
  // if (data.param) {
  //   callLib.open({
  //     param: {
  //       id: data.param || "",
  //       channelId: data.channelId || "",
  //       date: data.date || "",
  //       url: data.url || "",
  //       programId: data.programId || "",
  //     },
  //     path: data.path || "", //跳转的路径 不传就首页
  //   });
  // } else {
  //   console.log(callLib.generateScheme({ path: data.path || "" }));
  //   callLib.open({
  //     path: data.path || "",
  //   });
  // }
  console.log(callLib,'linkAPP_callLib')
  callLib.open({
    path:  "",
  });
}

