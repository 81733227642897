// 正式

const baseurl = 'https://art.njqiahao.com'
// const baseurl = 'http://192.168.2.10:8082'

let RESOURCE_URL = baseurl + '/appWeb' //线上资源路径
let BASE_API = baseurl + '/api'
let BASE_ART_API = `${BASE_API}/art` // 内网测试
// 测试 1
// let information = "http://106.54.252.48:8099/api"
// let BASE_API = "http://106.54.252.48:8099/api"
// 测试 2
// let information = "http://192.168.5.185:8099/api"
// let BASE_API = "http://192.168.5.185:8099"
// let RESOURCE_URL = process.env.VUE_APP_RESOURCE_URL //资源位置
console.log(BASE_ART_API, 'BASE_ART_API', process.env)
export default {
    RESOURCE_URL, //
    BASE_API, // 上传图片
    BASE_ART_API
}