export const appOptions = {
    name: '快艺学', //机构名称
    logoIcon: 'logo', //logo,
    appId: 'wx522c17330b3c124d',
    downLoadUrl: 'https://www.pgyer.com/FF16',
    appStoreUrl: 'https://apps.apple.com/app/id1603955781',
    
    address: '江苏省南京市秦淮区秦淮硅巷软件大厦3楼',
    introduction: '这是一款集考级、学习、智能学生管理为一体的APP，提供音乐教学管理服务的全方位平台。并根据市场需求导向，提供好用的解决方案。'
}

