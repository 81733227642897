// material

// 教材模块路由配置
export default [{
        path: "/czh",
        name: "czh",
        component: () => import("@/views/czh.vue"),
        meta: {
            title: "",
        },
    },
    {
        path: "/czhWengao",
        name: "czhWengao",
        component: () => import("@/views/czhWengao.vue"),
        meta: {
            title: "",
        },
    },
    {
        path: "/czhTuji",
        name: "czhTuji",
        component: () => import("@/views/czhTuji.vue"),
        meta: {
            title: "",
        },
    },
    {
        path: "/czhShipin",
        name: "czhShipin",
        component: () => import("@/views/czhShipin.vue"),
        meta: {
            title: "",
        },
    },
    {
        path: "/shipin",
        name: "shipin",
        component: () => import("@/views/shipin.vue"),
        meta: {
            title: "",
        },
    },
    {
        path: "/tuji",
        name: "tuji",
        component: () => import("@/views/tuji.vue"),
        meta: {
            title: "",
        },
    },
    {
        path: "/wengao",
        name: "wengao",
        component: () => import("@/views/wengao.vue"),
        meta: {
            title: "",
        },
    },
    {
        path: "/wenzheng",
        name: "wenzheng",
        component: () => import("@/views/wenzheng.vue"),
        meta: {
            title: "",
        },
    },
    {
        path: "/yinpin",
        name: "yinpin",
        component: () => import("@/views/yinpin.vue"),
        meta: {
            title: "",
        },
    },
    {
        path: "/zhuanti",
        name: "zhuanti",
        component: () => import("@/views/zhuanti.vue"),
        meta: {
            title: "",
        },
    },
    {
        path: "/kds",
        name: "kds",
        component: () => import("@/views/kds.vue"),
        meta: {
            title: "",
        },
    },

    {
        path: "/previewwengao",
        name: "previewwengao",
        component: () => import("@/views/previewwengao.vue"),
        meta: {
            title: "预览文稿",
        },
    },
    {
        path: "/previewyinpin",
        name: "previewyinpin",
        component: () => import("@/views/previewyinpin.vue"),
        meta: {
            title: "预览音频",
        },
    },
    {
        path: "/previewshipin",
        name: "previewshipin",
        component: () => import("@/views/previewshipin.vue"),
        meta: {
            title: "预览视频",
        },
    },
    {
        path: "/previewtuji",
        name: "previewtuji",
        component: () => import("@/views/previewtuji.vue"),
        meta: {
            title: "预览图集",
        },
    },
    {
        path: "/form",
        name: "form",
        component: () => import("@/views/form.vue"),
        meta: {
            title: "活动",
        },
    },
    {
        path: "/vote",
        name: "vote",
        component: () => import("@/views/vote.vue"),
        meta: {
            title: "投票",
        },
    },
]