import url_base from '@/config/settingConfig/base'
//通用系列
const {
  BASE_ART_API,
  BASE_API
} = url_base

export default {
  cityAreaList: `${BASE_ART_API}/city/areaList`, // 省市区三级联动列表 无参

  // 联系我们
  getAboutUs: `${BASE_API}/aboutUs/getAboutUs`, //获取关于我们信息

  
}
