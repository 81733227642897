export default  [
    {
      path: "/extra/party100",
      name: "party100",
      component: () => import("@/views/extra/party100/index.vue"),
      meta: {
        title: "党建宣言",
      },
    },
    {
      path: "/extra/audioDetail",
      name: "audioDetail",
      component: () => import("@/views/extra/party100/detail.vue"),
      meta: {
        title: "党建宣言",
      },
    },
  ];
  